import * as React from "react";
import styled from "styled-components";
import { Header, TechnologiesUsed, Footer, Image } from "../components";

const KrazyCouponLadyProjectStyles = styled.div`
  p,
  ul {
    color: ${(props) => props.theme.text.paragraph};
  }
  .project-description {
    width: 66.6667%;
    margin: 0 auto 80px;
  }
  h1 {
    text-align: center;
  }
  .project-description p {
    text-align: center;
    color: ${(props) => props.theme.text.paragraph};
  }
  .about-the-project-container {
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }
  .clients-words-container {
    margin-top: 60px;
  }
  .client-images-container {
    width: 100%;
  }
  .project-images-container {
    width: 100%;
  }
  .client-image-wrapper {
    margin-bottom: 111px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .client-description-container {
    width: 100%;
    margin: 0 auto;
    padding: 0 17px;
  }
  .client-description-quote {
    border-left: 1px solid #ddd;
    padding-left: 1.618em;
    margin: 0;
  }
  .project-image-container {
    width: 100%;
  }
  .project-image-wrapper {
    padding: 17px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 640px) {
    .about-the-project-container {
      display: block;
    }

    .project-image-wrapper {
      display: flex;
      justify-content: center;
    }
    .client-description-container {
      width: 92%;
    }
    /* .client-image-wrapper a {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    } */
  }
`;

const gridItems = [
  { link: "", imageName: "react", text: "react" },
  { link: "", imageName: "mongoDBImage", text: "mongoDB" },
  { link: "", imageName: "nextjsImage", text: "next.js" },
  { link: "", imageName: "githubImage", text: "github" },
  { link: "", imageName: "circleciImage", text: "circleCI" },
  { link: "", imageName: "awsImage", text: "aws" },
  { link: "", imageName: "cypressImage", text: "cypress.io" },
  { link: "", imageName: "graphqlImage", text: "graphQL" },
  { link: "", imageName: "apolloImage", text: "apollo" },
];

const bulletItems = [
  { bullet: "React", subBullets: [] },
  { bullet: "Next.js", subBullets: [] },
  { bullet: "MongoDB", subBullets: [] },
  { bullet: "React Native", subBullets: [] },
  { bullet: "GraphQL and Apollo", subBullets: [] },
  {
    bullet: "Amazon Web Services (Lambda, ECS, S3, Cloudformation)",
    subBullets: [],
  },
  { bullet: "CircleCI", subBullets: [] },
  { bullet: "Github", subBullets: [] },
  { bullet: "Cypress (web automated testing)", subBullets: [] },
];

const KrazyCouponLadyPagePage = () => (
  <main>
    <Header />
    <KrazyCouponLadyProjectStyles>
      <div className="project-description">
        <h1>The Krazy Coupon Lady</h1>
        <p>
          We helped&nbsp;
          <span>
            <a href="https://thekrazycouponlady.com/">The Krazy Coupon Lady</a>
          </span>
          &nbsp;with tech advising and strategy along with development on their
          mainly Javascript-based tech stack.
        </p>
        <p>
          We help out with many projects, both customer facing and internal,
          including their very popular&nbsp;
          <span>
            <a href="https://thekrazycouponlady.com/">couponing web app</a>
          </span>
          , and their mobile app on&nbsp;
          <span>
            <a href="https://apps.apple.com/us/app/krazy-coupon-lady-shop-deals/id962133447">
              iOS
            </a>
          </span>
          &nbsp;and&nbsp;
          <span>
            <a href="https://play.google.com/store/apps/details?gl=US&hl=en_US&id=com.thekrazycouponlady.kcl">
              Android
            </a>
          </span>
          .
        </p>
      </div>
      <TechnologiesUsed
        gridItems={gridItems}
        bulletItems={bulletItems}
        columnCount={3}
      />
      <div className="about-the-project-container">
        <div className="client-images-container">
          <div className="client-image-wrapper">
            <a href="https://thekrazycouponlady.com/">
              <Image
                imageName="krazy-coupon-lady-image"
                alt="The Krazy Coupon Lady"
              />
            </a>
          </div>
        </div>
        <div className="client-description-container">
          <h3>About the Client (The Krazy Coupon Lady)</h3>
          <p>
            <a href="https://thekrazycouponlady.com/">
              <span>The Krazy Coupon Lady</span>
            </a>{" "}
            is the place to go to learn how to save money. Founded and located
            in Boise, it’s a company with nationwide reach right here in our own
            backyard.
          </p>
          <div className="client-description-quote">
            <p>
              “KCL has managed to achieve the seemingly impossible task of being
              a company who continues to grow, yet has managed to hang on to
              their fun, hard working, scrappy culture that got them to this
              point.” — Jeremy Zerr, Founder, Zerrtech
            </p>
          </div>
          <div className="clients-words-container">
            <h3>In the Client&apos;s Words</h3>
            <div className="client-description-quote">
              <p>
                “Jeremy and his team at Zerrtech have been fundamental to our
                success over the last few years, and I continue to be completely
                blown away by them! It&apos;s been a wild (and fun) ride here
                over, and Zerrtech has stuck with us through our rapid change
                and growth. Jeremy took time to sit with me and share his
                perspective and learnings on not just our tech stack, but on
                team growth and development. I&apos;d recommend him and his team
                to any business needing help while they scale. As many
                businesses can attest to, it&apos;s hard to find external teams
                that can approach the work with serious expertise, but absent of
                any ego. Zerrtech is a prime example of this. We have had a
                blast learning from, and working alongside, them!” — Kim Mora,
                Chief Operating Officer,{" "}
                <span>
                  <a href="https://www.linkedin.com/in/kimberly-mora-03a12730/">
                    LinkedIn
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-the-project-container">
        <div className="project-images-container">
          <div className="project-image-wrapper">
            <Image imageName="kcl-project-role-1" alt="Project Role 1" />
          </div>
          <div className="project-image-wrapper">
            <Image imageName="kcl-project-role-2" alt="Project Role 2" />
          </div>
        </div>
        <div className="client-description-container">
          <h3>Project Roles</h3>
          <p>
            Our roles for KCL include:
            <ul>
              <li>
                Coding solutions in their web development stack using React,
                Apollo, GraphQL, and Next.js
              </li>
              <li>
                React Native development that powers both iOS and Android mobile
                apps
              </li>
              <li>
                Providing strategy around QA processes and designing automated
                tests for web and mobile
              </li>
              <li>
                Creating Amazon Web Services cloud architecture to host
                applications, including Cloudformation templates to ease
                integration of apps we lead into existing AWS infrastructure
              </li>
              <li>
                Architect data models using MongoDB and creating GraphQL
                endpoints
              </li>
              <li>
                Working with designs provided by KCL internal design team to
                implement responsive UI across web and app
              </li>
            </ul>
          </p>
        </div>
      </div>
    </KrazyCouponLadyProjectStyles>
    <Footer />
  </main>
);

export default KrazyCouponLadyPagePage;
